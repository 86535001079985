import { Dialog, DialogProps, DialogTitle } from "@mui/material";
import { useTranslation } from "react-i18next";

interface RADialogProps extends DialogProps {
  dialogTitle?: string;
  alignment?: string;
  onExited?: () => void;
}

export default function RADialog(props: RADialogProps) {
  const { onClose, onExited, open, dialogTitle, children } = props;
  const [t] = useTranslation();
  return (
    <Dialog
      {...props}
      onClose={onClose}
      TransitionProps={{ onExited: onExited }}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title" marginBottom={2}>
        {dialogTitle ? dialogTitle : t("no_title")}
      </DialogTitle>
      {children}
    </Dialog>
  );
}
