import { gql } from "@apollo/client";
import { ID } from "../../types/types";

export interface ConfigValueParams {
  value: string;
  entity: ID;
  valueFrom: string;
  key: string;
}

export const setConfigValuesMutation = gql`
  mutation SetConfigValues($customerId: ID!, $params: [setConfigValueInput!]!) {
    setConfigValues(customerId: $customerId, params: $params) {
      entity
      key
      value
      valueFrom
    }
  }
`;
