import {
  ProductKeysMap,
  ProductKeysMapLowerCase,
  ProductLabelsMap,
  ProductStatus,
  ProductType,
} from "../types/products";

export const DDBCMS = "ddbcms";

export const productLabels = [
  "access",
  "app",
  "assist",
  "butler",
  "people_counter",
  "ereolen",
  "ereolengo",
  "gates",
  "inspire",
  "library_app",
  "poster",
  "staff_rfid_studio",
];

export const productKeysMap: ProductKeysMap = {
  APP: "APP",
  ASSIST: "ASSIST",
  BUTLER: "BUTLER",
  CONTENT: "CONTENT",
  PEOPLE_COUNTER: "PEOPLE_COUNTER",
  DOOR_ACCESS: "DOORACCESS",
  EREOLEN: "EREOLEN",
  EREOLENGO: "EREOLENGO",
  GATES: "GATES",
  INSPIRE: "INSPIRE",
  LIBRARY_APP: "LIBRARY_APP",
  POSTER: "POSTER",
  STAFF_RFID_STUDIO: "STAFF_RFID_STUDIO",
};

export const productKeysMapLowerCase: ProductKeysMapLowerCase = {
  APP: "app",
  ASSIST: "assist",
  BUTLER: "butler",
  CONTENT: "content",
  PEOPLE_COUNTER: "people_counter",
  DOOR_ACCESS: "dooraccess",
  EREOLEN: "ereolen",
  EREOLENGO: "ereolengo",
  GATES: "gates",
  INSPIRE: "inspire",
  LIBRARY_APP: "libraryapp",
  POSTER: "poster",
  STAFF_RFID_STUDIO: "staff_rfid_studio",
};

export const productLabelsMap: ProductLabelsMap = {
  APP: "app",
  ASSIST: "assist",
  BUTLER: "butler",
  CONTENT: "content",
  PEOPLE_COUNTER: "people_counter",
  DOORACCESS: "access",
  EREOLEN: "ereolen",
  EREOLENGO: "ereolengo",
  GATES: "gates",
  INSPIRE: "inspire",
  LIBRARY_APP: "library_app",
  POSTER: "poster",
  STAFF_RFID_STUDIO: "staff_rfid_studio",
};

export const thirdPartyProductsList: ProductStatus[] = [
  { type: productKeysMap.CONTENT, enabled: false },
];

export const productList: ProductStatus[] = [
  { type: productKeysMap.BUTLER, enabled: false },
  { type: productKeysMap.APP, enabled: false },
  { type: productKeysMap.LIBRARY_APP, enabled: false },
  { type: productKeysMap.ASSIST, enabled: false },
  { type: productKeysMap.GATES, enabled: false },
  { type: productKeysMap.DOOR_ACCESS, enabled: false },
  { type: productKeysMap.INSPIRE, enabled: false },
  { type: productKeysMap.PEOPLE_COUNTER, enabled: false },
  { type: productKeysMap.STAFF_RFID_STUDIO, enabled: false },
];
