import {
  BaseSelectProps,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  useTheme,
} from "@mui/material";
import { FlexGrid, TooltipFrame } from "../../layouts/Body";
import RATooltipIcon from "../RATooltipIcon";

interface SelectOption {
  value: string;
  label: string;
}

interface RASelectProps extends BaseSelectProps {
  greyText?: boolean;
  helperText?: string;
  onChange?: (event: any) => void;
  options?: SelectOption[];
  tooltipText?: string;
}

export function RASelect(props: RASelectProps) {
  const theme = useTheme();
  const value = props.value === undefined ? "" : props.value;
  const { tooltipText, helperText, greyText, MenuProps, ...rest } = props;

  return (
    <Grid container alignItems="flex-end" justifyContent="flex-start">
      <FlexGrid item xs>
        <FormControl required={props.required} fullWidth>
          {props.label && (
            <InputLabel disabled={props.disabled} shrink={props.displayEmpty}>
              {props.label}
            </InputLabel>
          )}
          <Select
            title=""
            MenuProps={{
              ...MenuProps,
              BackdropProps: { style: { backgroundColor: "transparent" } },
            }}
            id={props.id || ""}
            onBlur={props.onBlur || undefined}
            value={value}
            style={greyText ? { color: theme.palette.grey[600] } : {}}
            input={
              <OutlinedInput
                disabled={props.disabled}
                notched={props.displayEmpty}
                label={props.label}
              />
            }
            {...rest}
          >
            {props.options &&
              props.options.map((option: SelectOption) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            {props.children}
          </Select>
          {!!helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
        {tooltipText && (
          <TooltipFrame
            bottompadding={!!helperText ? helperText.toString() : "false"}
          >
            <RATooltipIcon fontSize="medium" description={tooltipText} />
          </TooltipFrame>
        )}
      </FlexGrid>
    </Grid>
  );
}
