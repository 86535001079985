import { Divider } from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import MenuItem from "@mui/material/MenuItem";
import { CSSObject, Theme, styled, useTheme } from "@mui/material/styles";
import { filter, sortBy } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { routes } from "../../Routes";
import { useAuth0 } from "../../auth0/ReactAuth0Wrapper";
import { RASelect } from "../../components/Inputs/RASelect";
import { productKeysMap } from "../../constants/Products";
import { PARTNERADMIN, SYSTEMEMPLOYEE } from "../../constants/authentication";
import { drawerWidth } from "../../constants/body";
import { allCustomersId } from "../../constants/constants";
import { stateCategoryMap } from "../../constants/state";
import { subjectKeysMap, subjectLabels } from "../../constants/subjects";
import { useIsPermitted } from "../../hooks/authorization";
import CustomerControls from "../../routes/Customers/components/CustomerControls";
import { CustomerState, useGlobalState } from "../../state";
import { IdNamePair } from "../../types/interfaces";
import { SubjectLabel } from "../../types/subjects";
import { ContentStack, FormSectionStack } from "../Body";
import HelpCenterOption from "./components/HelpCenterOption";
import NavigationList from "./components/NavigationList";
import { pathIncludesSubject } from "../../utils/utils";

const FillHeightDiv = styled("div")`
  display: flex;
  flex: 1;
`;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

function LeftMenu() {
  const [t, i18n] = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth0();
  const { isPermitted: systemPermission } = useIsPermitted(SYSTEMEMPLOYEE);
  const { isPermitted: advPermission } = useIsPermitted(PARTNERADMIN);
  const [customer] = useGlobalState(stateCategoryMap.CUSTOMER);
  const [_globalLoading, setGlobalLoading] = useGlobalState(
    stateCategoryMap.GLOBAL_LOADING
  );
  const isGlobalConfiguration: boolean = customer.id === allCustomersId;
  const subjects: IdNamePair[] = [
    { id: "", name: t("left_menu.homepage") },
    { id: subjectKeysMap.ACCESS, name: t("access.title") },
    { id: subjectKeysMap.APP, name: t("app.title") },
    { id: subjectKeysMap.ASSIST, name: t("assist.title") },
    { id: subjectKeysMap.BUTLER, name: t("butler.title") },
    { id: subjectKeysMap.GATES, name: t("gates.title") },
    { id: subjectKeysMap.GENERAL, name: t("general.title") },
    { id: subjectKeysMap.INSPIRE, name: t("inspire.title") },
    { id: subjectKeysMap.LIBRARY_APP, name: t("library_app.title") },
    { id: subjectKeysMap.PEOPLE_COUNTER, name: t("people_counter.title") },
    { id: subjectKeysMap.POSTER, name: t("poster.title") },
    {
      id: subjectKeysMap.STAFF_RFID_STUDIO,
      name: t("products.staff_rfid_studio"),
    },
  ];
  const [subject, setSubject] = useState<SubjectLabel | "">("");
  const [fixedSubjects, setFixedSubjects] = useState<IdNamePair[]>([]);
  const [filteredSubjects, setFilteredSubjects] = useState<IdNamePair[]>([]);
  const [open, setOpen] = useState<boolean>(true);
  const [customerState, setCustomerState] = useState<CustomerState>();
  const isDarkMode = useTheme().palette.mode === "dark";

  useEffect(() => {
    if (location.pathname === routes.home) {
      setSubject("");
    }
    subjectLabels.forEach((s: SubjectLabel) => {
      if (pathIncludesSubject(location.pathname, s)) {
        setSubject(s);
        return;
      }
    });
  }, [location.pathname]);

  useEffect(() => {
    if (customer) {
      setCustomerState(customer);
    }
  }, [customer]);

  useEffect(() => {
    if (customerState && customerState.products) {
      const fixed: IdNamePair[] = filter(subjects, (subject: IdNamePair) => {
        if (subject.id === subjectKeysMap.GENERAL || subject.id === "") {
          return true;
        }
        return false;
      });
      const filtered: IdNamePair[] = filter(subjects, (subject: IdNamePair) => {
        if (subject.id === subjectKeysMap.BUTLER && isGlobalConfiguration) {
          return true;
        }
        for (let i = 0; i < customerState.products.length; i++) {
          if (
            customerState.products[i].type === productKeysMap.LIBRARY_APP &&
            subject.id === subjectKeysMap.LIBRARY_APP &&
            systemPermission
          ) {
            return !!customerState.products[i].enabled;
          }
          if (
            customerState.products[i].type === productKeysMap.DOOR_ACCESS &&
            subject.id === subjectKeysMap.ACCESS
          ) {
            return !!customerState.products[i].enabled;
          }
          if (customerState.products[i].type.toLowerCase() === subject.id) {
            return !!customerState.products[i].enabled;
          }
        }
        return false;
      });
      const sorted: IdNamePair[] = sortBy(filtered, [
        function (subject) {
          return subject.name;
        },
      ]);
      setFixedSubjects(fixed);
      setFilteredSubjects(sorted);
    }
  }, [customerState, i18n.language, isGlobalConfiguration]);

  if (!isAuthenticated) {
    return null;
  }

  function handleSubjectClick(subject: SubjectLabel) {
    setSubject(subject);
    switch (subject) {
      case subjectKeysMap.GENERAL:
        navigate(routes.general.root);
        break;
      case subjectKeysMap.ACCESS:
        navigate(routes.access.root);
        break;
      case subjectKeysMap.ASSIST:
        navigate(routes.assist.root);
        break;
      case subjectKeysMap.BUTLER:
        navigate(routes.butler.root);
        break;
      case subjectKeysMap.INSPIRE:
        navigate(routes.inspire.root);
        break;
      case subjectKeysMap.APP:
        navigate(routes.app.root);
        break;
      case subjectKeysMap.LIBRARY_APP:
        navigate(routes.library_app.root);
        break;
      case subjectKeysMap.GATES:
        navigate(routes.gates.root);
        break;
      case subjectKeysMap.PEOPLE_COUNTER:
        navigate(routes.people_counter.root);
        break;
      case subjectKeysMap.STAFF_RFID_STUDIO:
        navigate(routes.staff_rfid_studio.root);
        break;
      default:
        navigate(routes.home);
        break;
    }
  }

  function handleLoading() {
    if (location.pathname !== routes.home) {
      setGlobalLoading(true);
    }
  }

  return (
    <Drawer variant="permanent" open={open}>
      <ContentStack
        paddingLeft={0}
        paddingRight={0}
        paddingTop={5}
        paddingBottom={5}
        disableDividers
        flex={1}
      >
        <FormSectionStack paddingLeft={4} paddingRight={4}>
          <Link to={routes.home} onClick={() => handleLoading()}>
            <img
              src={
                isDarkMode ? "/redia-logo-white.svg" : "/redia-logo-black.svg"
              }
              alt="Logo"
              style={{ width: "130px" }}
              decoding="auto"
            />
          </Link>
          {!!customer.id && (
            <RASelect
              displayEmpty
              id="choose_subject"
              label={t("left_menu.subject")}
              onChange={(event: any) => handleSubjectClick(event.target.value)}
              value={subject}
            >
              {fixedSubjects.map((option: IdNamePair) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
              {filteredSubjects.length > 0 && <Divider />}
              {filteredSubjects.map((option: IdNamePair) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </RASelect>
          )}
        </FormSectionStack>
        <Divider variant="middle" />
        {subject && <NavigationList subject={subject} />}
        <FillHeightDiv />
        <HelpCenterOption />
        {advPermission && (
          <>
            <Divider variant="middle" />
            <CustomerControls />
          </>
        )}
      </ContentStack>
    </Drawer>
  );
}

export default LeftMenu;
