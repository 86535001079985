import { useQuery } from "@apollo/client";
import { Button, Grid, LinearProgress } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { orderBy } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import useCookie from "react-use-cookie";
import {
  allCustomersForPartnerQuery,
  allCustomersQuery,
} from "../../../GraphQL/customer/queries";
import { routes } from "../../../Routes";
import RADialog from "../../../components/RADialog";
import {
  PARTNERADMIN,
  SYSTEMEMPLOYEE,
} from "../../../constants/authentication";
import { stateCategoryMap } from "../../../constants/state";
import { useIsPermitted } from "../../../hooks/authorization";
import { ContentStack, FormSectionStack } from "../../../layouts/Body";
import { useGlobalState } from "../../../state";
import { IdNamePair } from "../../../types/interfaces";
import CreateCustomerForm from "../components/CreateCustomerForm";
import CustomerSelector from "../components/CustomerSelector";

function CustomerControls() {
  const [t] = useTranslation();
  const { isPermitted: permittedForAllCustomers } =
    useIsPermitted(SYSTEMEMPLOYEE);
  const { isPermitted: permittedForPartnerCustomers } =
    useIsPermitted(PARTNERADMIN);
  const navigate = useNavigate();
  const [globalCustomer, setGlobalCustomer] = useGlobalState(
    stateCategoryMap.CUSTOMER
  );
  const [open, setOpen] = useState(false);
  const [customers, setCustomers] = useState<IdNamePair[]>([]);
  const {
    loading: loadingCustomers,
    error: customersError,
    data: customersData,
    refetch: customersRefetch,
  } = useQuery(allCustomersQuery, {
    fetchPolicy: "no-cache",
    skip: !permittedForAllCustomers,
  });
  const {
    loading: loadingPartnerCustomers,
    error: partnerCustomersError,
    data: partnerCustomersData,
    refetch: partnerCustomersRefetch,
  } = useQuery(allCustomersForPartnerQuery, {
    fetchPolicy: "no-cache",
    skip: permittedForAllCustomers,
  });
  const [customerCookie, setCustomerCookie] = useCookie("customerId", "");
  const [saveCookie, setSaveCookie] = useState<boolean>(customerCookie !== "");

  useEffect(() => {
    if (customersData && customersData.allCustomers) {
      setCustomers(orderBy(customersData.allCustomers, "name", "asc"));
    }
  }, [customersData]);

  useEffect(() => {
    if (partnerCustomersData && partnerCustomersData.allCustomersForPartner) {
      setCustomers(
        orderBy(partnerCustomersData.allCustomersForPartner, "name", "asc")
      );
    }
  }, [partnerCustomersData]);

  useEffect(() => {
    if (!!globalCustomer?.update) {
      customersRefetch();
    }
  }, [globalCustomer]);

  if (loadingCustomers || loadingPartnerCustomers) return <LinearProgress />;
  if (customersError) {
    return <p>{t("error_fetch_contact_redia")}</p>;
  }
  if (partnerCustomersError) {
    return <p>{t("error_fetch_contact_redia")}</p>;
  }

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  function handleCustomerClick(id: string) {
    setGlobalCustomer({
      id: id,
      name: "",
      customerError: false,
      loading: true,
      update: false,
      products: [],
      partnerId: "",
    });
    setCustomerCookie(saveCookie ? id : "");
    navigate(routes.home);
  }

  function onCustomerCreateClosed(refetchCustomers: boolean) {
    setOpen(false);
    if (refetchCustomers) {
      if (permittedForAllCustomers) {
        customersRefetch();
      } else if (permittedForPartnerCustomers) {
        partnerCustomersRefetch();
      }
    }
  }

  function onCookieChange(value: boolean) {
    if (value) setCustomerCookie(globalCustomer.id);
    if (!value) setCustomerCookie("");
    setSaveCookie(value);
  }

  return (
    <>
      <ContentStack paddingBottom={0} paddingTop={0}>
        <FormSectionStack alignItems="center" spacing={2}>
          <CustomerSelector
            onCustomerClick={(id: string) => handleCustomerClick(id)}
            customerId={globalCustomer.id}
            customers={customers}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={saveCookie}
                onChange={(event) => onCookieChange(event.target.checked)}
              />
            }
            label={t("customers.save_id_in_cookie")}
          />
          {permittedForAllCustomers && (
            <Grid>
              <Button variant="text" onClick={handleClickOpen}>
                {t("customers.create_customer")}
              </Button>
            </Grid>
          )}
        </FormSectionStack>
      </ContentStack>
      <RADialog
        fullWidth
        maxWidth="sm"
        onClose={handleClose}
        open={open}
        dialogTitle={t("customers.create_customer")}
      >
        <CreateCustomerForm onClose={onCustomerCreateClosed} />
      </RADialog>
    </>
  );
}

export default CustomerControls;
